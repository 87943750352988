<template>
	<div class="project-member-container">
		<div class="user-container">
			<div class="user-title">
				<div class="title-left">
					<el-button v-if="spacePermiss.isAdmin||spacePermiss.isSmartAdmin||spacePermiss.isSpaceOwner" type="primary" @click="addUser">邀请用户</el-button>
				</div>
				<div class="title-right">
					<span>成员数量<i>&nbsp;{{userList.length}}&nbsp;</i>人</span>
				</div>
			</div>
			<div class="user-list">
				<ul>
					<li><span style="background-color: rgba(0,0,0,0);"></span><span>昵称</span><span>用户</span><span>角色</span><span>操作</span></li>
					<li v-for="(item,index) of userList">
						<span>{{item.firstChart}}</span>
						<span>{{item.nickName}}</span>
						<span>{{item.userName}}</span>
						<span v-if="item.spaceRole.length==0">拥有者</span>
						<span v-else>{{item.spaceRole[0].spaceRoleName}}</span>
						<span v-if="item.spaceRole.length!=0" @click="deleteUser(item)" class="el-icon-close closeBtn"  ></span>
						<span v-else></span>
					</li>
				</ul>
			</div>
			<div class="leave-box">
				<el-button v-if="!(spacePermiss.isSpaceOwner&&userIsOwner)"  type="danger" @click="returnProject">退出项目</el-button>
			</div>
		</div>
		<!-- <el-dialog title="邀请用户" :visible.sync="addUserDia" width="30%">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="用户名" prop="addUserName">
					<el-input v-model="ruleForm.addUserName"></el-input>
				</el-form-item>
			</el-form>
			<p style="text-align: right;">
				<el-button type="danger" @click="addUserDia = false">取消</el-button>
				<el-button type="primary" @click="sureAdd">确认</el-button>

			</p>
		</el-dialog> -->
		<div v-if="projectUserAddDia">
			<add-user ref="addUser" :userTableList="userTableList"  :userSource = "userSource" :userValueC="userValue" :isUser="true"></add-user>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import AddUser from '@/components/addUser.vue'
export default {
  components: {
    AddUser
  },
  data () {
    return {
      userList: [],
      addUserDia: false,
      ruleForm: {
        addUserName: ''
      },
      rules: {
        addUserName: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }]
      },
      userId: 0,
      userIsOwner: false,
      userTableList: [],
      userSource: [],
      userValue: [],
      projectUserAddDia: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
		  ...mapState('spacePermiss', {
		    spacePermiss: 'spacePermiss'
		  })
  },
  methods: {
    init () {
      this.userValue = []
      this.userSource = []
      this.$axios.get(`/api/user/getspaceuserlist?SpaceId=${localStorage.getItem('eleSpaceId')}`).then(res => {
        this.userTableList = []
        res.forEach(element => {
          this.userTableList.push({
            key: element.userId,
            label: element.nickName,
            disabled: false,
            userName: element.userName
          })
        })
      })
      this.userId = localStorage.getItem('USER_ID')
      this.$axios.get('/api/User/GetUsers').then(res => {
        console.log(res)
        this.userList = res
        let noDeleteList = []
        this.userList.forEach(element => {
          element.firstChart = element.nickName.slice(0, 1)
          this.userSource.push(element.userId)
          if (this.userTableList.findIndex(n => n.key == element.userId) > -1) {
            this.userValue.push(element.userId)
          }
          if (element.spaceRole.length == 0 || !(element.spaceRole.length > 0 && element.spaceRole[0].statu == 2)) {
            noDeleteList.push(element.userId)
          }
        })
        if (this.userId == this.userList[0].userId) {
          this.userIsOwner = true
          console.log(this.userIsOwner)
        }
        console.log(noDeleteList)
        this.userTableList.forEach(item => {
          if (this.userValue.find(item2 => item2 == item.key) && noDeleteList.find(item3 => item3 == item.key)) {
            item.disabled = true
          } else {
            item.disabled = false
          }
        })
      })
    },
    cancle () {
      this.dialogUserTable = false
    },
    deleteUser (item) {
      console.log(item)
      let pid = localStorage.getItem('eleProjectId')
      if (item.spaceRole.length > 0 && item.spaceRole[0].statu == 2) {
        this.$confirm('成员删除后，该成员将无法查看到该项目任何数据直至重新加入该项目。', '提示', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios.get(`/api/project/deleteprojectuser?userId=${item.userId}&projectId=${pid}`).then(res => {
            this.$message({
              message: res,
              type: 'success'
            })
            this.dialogUserTable = false
            this.init()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } else {
        this.$message({
          type: 'warning',
          message: '对不起，您无法删除该用户。'
        })
      }
    },
    addUser () {
      this.projectUserAddDia = true
      // this.addUserDia = true
      setTimeout(() => this.$refs.addUser.dialogUserTable = true, 10)
    },
    sureAdd () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$axios.get(`/api/project/projectuser?userName=${this.ruleForm.addUserName}&projectId=${localStorage.eleProjectId}`).then(res => {
            // console.log(res)
            if (res.userId) {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.init()
              // res.firstChart = res.nickName.slice(0, 1)
              // this.userList.push(res)
            } else {
              this.$message({
                message: res,
                type: 'error'
              })
            }
            this.addUserDia = false
          })
        }
      })
    },
    returnProject () {
      this.$confirm('退出后，将无法查看该项目任何数据，直至重新加入该项目，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
        this.$axios.get(`/api/project/deleteprojectuser?userId=${userInfo.userId}&projectId=${localStorage.eleProjectId}`).then(res => {
          this.$message({
            message: res,
            type: 'success'
          })
          this.$router.push('/project')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
	.project-member-container {
		// height: calc(100vh - 60px);
		height: 877px;
		width: 1100px;
		// margin: auto;
		box-sizing: border-box;
		padding: 20px;
		.el-button--primary {
			background: #475065;
			border: none;
		}
		.el-button--danger {
			border: none;
		}
		// text-align: left;
		// background-color: #000000;
		.user-container {
			box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
			border-radius: 8px;
			background-color: #FFFFFF;
			padding: 20px;
			// height: calc(100vh - 170px);
			// height: 780px;
			height: 830px;
			overflow-y: auto;

			.user-title {
				// background-color: #000066;
				display: flex;
				justify-content: space-between;
				font-size: 20px;
				text-align: right;
				height: 50px;
				line-height: 50px;
				padding: 30px 150px 0 10px;

				.title-left {
					.el-button--primary {
						background: #475065;
						border: none;
					}
				}

				i {
					font-size: 30px;
				}
			}

			.user-list {
				border-top: 1px solid #d0d0d0;
				padding: 30px 30px 0 30px;

				ul {
					// width: 100%;
					// background-color: #000003;
					height: 580px;
					overflow-y: auto;

					li {
						display: flex;
						justify-content: space-between;
						height: 50px;
						border-bottom: 1px solid #E0E0E3;
						// line-height: 40px;
						align-items: center;

						span {
							width: 30%;
							// background-color: #000066;
						}

						.closeBtn {
							cursor: pointer;
						}

						// span:nth-child(1){
						// 	width: 10%;
						// }
						span:nth-child(1) {
							display: inline-block;
							min-width: 30px;
							width: 30px;
							height: 30px;
							line-height: 30px;
							background-color: #333333;
							color: #fff;
							border-radius: 50%;
							font-size: 12px;
						}
					}
				}
			}

			.leave-box {
				padding: 20px 20px 0 20px;
				// background-color: #000066;
				text-align: right;
			}
		}
	}
</style>
